let BASE_URL = "https://api.inperson.us/v1"
export const sendLinkSMS = (postData) => {
    return new Promise((res, rej) => {
        fetch(BASE_URL + '/send-link-sms', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
        })
            .then((response) => response.json())
            .then((result) => {
                res(result)
            })
            .catch((error) => {
                console.error("Error sending SMS:", error);
                rej(error)
            });
    })
}
export const subscribedEmail = (postData) => {
    return new Promise((res, rej) => {
        fetch(BASE_URL + '/subscribed-updates', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
        })
            .then((response) => response.json())
            .then((result) => {
                res(result)
            })
            .catch((error) => {
                console.error("Error sending SMS:", error);
                rej(error)
            });
    })
}